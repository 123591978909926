<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.name"
                dense
                :label="$t('name_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.address"
                dense
                :label="$t('address_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="options.product"
                dense
                :items="['oem', 'fleet']"
                item-text="name"
                item-value="id"
                :disabled="type == 'edit'"
                :label="$t('select_product_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant"
                dense
                :loading="tenantLoading"
                :items="tenantsByProduct"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.latitude"
                dense
                :label="$t('latitude_*')"
                :rules="[rules.required, rules.latlng]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.longitude"
                dense
                :label="$t('longitude_*')"
                :rules="[rules.required, rules.latlng]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.capacity"
                dense
                :label="$t('capacity_*')"
                :rules="[rules.required, rules.min_quantity(0)]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="options.country"
                dense
                :items="countries"
                item-value="id"
                item-text="name"
                :label="
                  isLatLng ? $t('select_country') : $t('select_country_*')
                "
                :rules="[isLatLng || rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="options.state"
                dense
                :items="states"
                item-value="id"
                item-text="name"
                :disabled="!options.country"
                :label="isLatLng ? $t('select_state') : $t('select_state_*')"
                :rules="[isLatLng || rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="options.city"
                dense
                :items="cities"
                item-value="id"
                item-text="name"
                :disabled="!options.state"
                :label="isLatLng ? $t('select_city') : $t('select_city_*')"
                :rules="[isLatLng || rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.services"
                dense
                :items="serviceOptions"
                item-value="id"
                item-text="name"
                :label="$t('services_*')"
                :rules="[rules.multi_select_required]"
                multiple
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.operational_status"
                dense
                :items="statusOptions"
                item-value="id"
                item-text="name"
                :label="$t('status_*')"
                :rules="[rules.required_options]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <span class="text-h3">Contact Persons:</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.contact_1_name"
                dense
                :label="$t('name_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.contact_1"
                dense
                :label="$t('phone_number_*')"
                :rules="[
                  rules.required,
                  rules.startFrom(0),
                  rules.min_length(11),
                  rules.max_length(11),
                ]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.contact_2_name"
                dense
                :label="$t('name')"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.contact_2"
                dense
                :label="$t('phone_number')"
                :rules="[
                  (value) => !value || rules.min_length(11)(value),
                  (value) => !value || rules.startFrom(0),
                  (value) => !value || rules.max_length(11)(value),
                ]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small>{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
      locations: {},
      product: null,
      tenantOEM: [],
      statusOptions: [
        {
          id: 1,
          name: "active",
        },
        {
          id: 0,
          name: "inactive",
        },
      ],
      serviceOptions: [
        {
          id: "refueling",
          name: "refueling",
        },
        {
          id: "service",
          name: "service",
        },
        {
          id: "maintenance",
          name: "maintenance",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      countryCodeList: "getCountryCode",
      countries: "getCountries",
      states: "getStates",
      cities: "getCities",
    }),
    title() {
      if (this.type == "create") {
        return "add_new";
      } else {
        return "";
      }
    },
    tenantsByProduct() {
      if (this.options?.product) {
        if (this.options.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
    isLatLng() {
      return this.options.latitude || this.options.longitude ? true : false;
    },
  },
  watch: {
    "options.country": {
      handler: async function (val) {
        await this.$store.dispatch("setStates", { country_id: val });
      },
    },
    "options.state": {
      handler: async function (val) {
        await this.$store.dispatch("setCities", { state_id: val });
      },
    },
  },
  async mounted() {
    if (this.type == "edit") {
      this.options = { ...this.item };
    }
    await this.$store.dispatch("setCountries");
  },
  methods: {
    saveItem() {
      this.options.status = this.options.operational_status;
      this.options.location =
        this.options.latitude + "," + this.options.longitude;
      if (this.options.contact_2 == null) {
        delete this.options.contact_2;
      }
      if (this.options.contact_2_name == null) {
        delete this.options.contact_2_name;
      }
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.$emit("onclose");
    },
  },
};
</script>
